import { AfterViewInit, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, timeout } from 'rxjs/operators';
import { FormService } from './services';
import { WebClaimsFormService } from './services';
import {
  IFrameFetchSuccessAction,
  LanguageSelectionSuccessAction,
  LoadingIndicatorHideAction,
  LoadingIndicatorShowAction,
  saveDashboardDeatils,
} from './state/actions';

import { Observable, Subscription } from 'rxjs';
import {
  ActivatedRoute,
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { environment } from '../environments/environment';
import {
  formatApiError,
  getApiError,
  getRandomId,
  getServerError,
  formatApiResponse,
} from './widgets/shared/utilities';
import { MessagePopupService } from './services/message-popup.service';
// import { MsalService } from "@azure/msal-angular";
import { IdlingService } from '@crux/services';
import { AuthProviderService } from './auth-provider-service';
import { Adb2cState } from '@chubb-auth/types';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { SpinningIndicatorComponent } from './widgets/spinning-indicator/spinning-indicator.component';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { FormValidationService } from './services/form-validation.service';
import { AppContextService } from './app.context.service';
import { GeneralizedPopUpComponent } from './widgets/shared/generalized-popup/generalized-popup.component';
import {
  NotificationService,
  NotifListResponse,
} from './services/notification.service';
import { CustomEventService } from './services/custom-event.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import {
  AnalyticsModule,
  GoogleTagManagerService,
  ApplicationInsightsService,
} from '@crux/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  sessionId: any;
  storeObj: any;
  iFrameData = {};
  regionObj = {
    MSregion: '',
    CLregion: '',
    lsp: '',
    ClaimRegionName: '',
  };
  apiToken: any;
  sessionToken: any;
  displayData: any;
  authProviderState: Adb2cState;
  dialogRef: any;
  isApisTriggered: any;
  counter = 700;
  somethingWentWrongError: any;
  contactSupportPopUp: any;
  inactiveMessage: string;
  fullStoreObject;
  serverError: any;
  systemDownData: any;
  trueGroup = false;
  expandData: any = [];
  openedCCPANotice: boolean;
  constructor(
    private _gtm: GoogleTagManagerService,
    private _service: FormService,
    private _formService: WebClaimsFormService,
    private _store: Store<any>,
    // private broadcastService: BroadcastService,
    //private authService: MsalService,
    private _router: Router,
    private route: ActivatedRoute,
    private _idlePopup: MessagePopupService,
    private _authProviderService: AuthProviderService,
    private dialog: MatDialog,
    private _loadingService: LoadingIndicatorService,
    private _formValidationService: FormValidationService,
    private _appContext: AppContextService,
    private _notificationService: NotificationService,
    private _jwtHelperService: JwtHelperService,
    private _customEventService: CustomEventService,
    private _ai: ApplicationInsightsService
  ) {
    // this.openLoadingIndicator();
    this._loadingService.openLoadingIndicator({});
    this._router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });

    this._idlePopup.refreshToken$.subscribe((newAccessToken) => {
      this.sessionToken = newAccessToken;
      this.callAPIs();
    });

    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
      //code to check trueGroup for Educator Market
      if (decodedToken !== undefined && decodedToken.userType !== undefined) {
        if (decodedToken.userType == 'trueGroup') {
          this.trueGroup = true;
        } else {
          this.trueGroup = false;
        }
      }
    }

    this._appContext.loadConfig$.subscribe((flag) => {
      if (flag) {
        this.somethingWentWrongError = this._appContext.get(
          'somethingWentWrong'
        );
        this.contactSupportPopUp = this._appContext.get('contactSupportPopup');
        this.inactiveMessage = this._appContext.get(
          'pages.inactiveUserMessage'
        );
        this.serverError = this._appContext.get('serverError');
      }
    });

    this._store.subscribe((state) => {
      this.storeObj = cloneDeep(state);
      if (this.storeObj['iFrameData'] !== undefined) {
        this.iFrameData = this.storeObj['iFrameData'];
      } else {
        this.iFrameData = {};
      }
      this.fullStoreObject = state;

      if (
        this.fullStoreObject['iFrameData'] !== undefined &&
        this.fullStoreObject['iFrameData'].fetchNotif === true &&
        this.fullStoreObject['iFrameData'].hasNotifResponse === false
      ) {
        const filterDays = this.fullStoreObject['iFrameData'].periodValue;
        this.callNotifications(filterDays, true);
      }

      if (this.storeObj['iFrameData'] !== undefined) {
        this.openedCCPANotice = this.storeObj['iFrameData']?.openedCCPANotice;
      }
    });

    this._formValidationService.callDasboardApi$.subscribe((flg) => {
      if (flg) {
        if (!this.systemDownData) {
          this._service.getSystemDownTimeJson().subscribe(
            (resp) => {
              this.systemDownData = resp;
              if (
                this.systemDownData &&
                this.systemDownData.module &&
                this.systemDownData.module.main &&
                this.systemDownData.module.main.msg
              ) {
                this.formatSystemDownJSON();
                this._loadingService.closeLoadingIndicator();
                this._router.navigate(['/knock-out'], {
                  queryParams: {
                    formattedResponse: '',
                    systemDownData: JSON.stringify(this.systemDownData),
                    dashboardKnockout: true,
                  },
                });
              } else {
                this._loadingService.openLoadingIndicator({});
                if (this.trueGroup === true) {
                  this.callMemberDashboardInfoApi();
                } else {
                  this.callDashboarInfoApi();
                }
              }
            },
            (err) => {
              this._loadingService.openLoadingIndicator({});
              if (this.trueGroup === true) {
                this.callMemberDashboardInfoApi();
              } else {
                this.callDashboarInfoApi();
              }
            }
          );
        } else {
          if (
            this.systemDownData &&
            this.systemDownData.module &&
            this.systemDownData.module.main &&
            this.systemDownData.module.main.msg
          ) {
            this.formatSystemDownJSON();
            this._loadingService.closeLoadingIndicator();
            this._router.navigate(['/knock-out'], {
              queryParams: {
                formattedResponse: '',
                systemDownData: JSON.stringify(this.systemDownData),
                dashboardKnockout: true,
              },
            });
          } else {
            this._loadingService.openLoadingIndicator({});
            if (this.trueGroup === true) {
              this.callMemberDashboardInfoApi();
            } else {
              this.callDashboarInfoApi();
            }
          }
        }
      }
    });
  }

  callNotifications(FilterByDays: string = '>90', fetchNotif = false) {
    if (
      this.fullStoreObject['iFrameData'] !== undefined &&
      (this.fullStoreObject['iFrameData'].notifListResponse === undefined ||
        fetchNotif)
    ) {
      const policyList = this.fullStoreObject['iFrameData']['PolicyDetails'];
      if (policyList && policyList.length) {
        this.apiToken = this.fullStoreObject['iFrameData']['apiToken'];
        const payload = this.getPayloadForNotif(
          this.fullStoreObject['iFrameData'],
          FilterByDays
        );
        this._notificationService
          .getNotifications(payload, this.apiToken)
          .subscribe(
            (notifListResponse) => {
              if (notifListResponse.Code === '0000') {
                const unreadCount = notifListResponse.UnreadCount;
                this._store.dispatch(
                  new IFrameFetchSuccessAction({
                    iFrameData: {
                      ...this.iFrameData,
                      notifListResponse,
                      unreadCount,
                      pendingNotifCount: unreadCount,
                      hasNotifResponse: true,
                      fetchNotif: false,
                      notificationError: 'false',
                    },
                  })
                );
              } else {
                // const content = {
                //   subHeader:
                //     'Code: ' +
                //     notifListResponse['Code'] +
                //     ', ' +
                //     notifListResponse['Message'],
                // };
                // this.showSomethingWentWrongPopup(content);
                this._store.dispatch(
                  new IFrameFetchSuccessAction({
                    iFrameData: {
                      ...this.iFrameData,
                      notifListResponse,
                      unreadCount: 0,
                      hasNotifResponse: true,
                      fetchNotif: false,
                      notificationError: formatApiResponse(
                        notifListResponse,
                        environment.notification.notifHistory
                      ),
                    },
                  })
                );
              }
            },
            (error) => {
              // const content = getApiError(error);
              // this.showSomethingWentWrongPopup(content);
              this._store.dispatch(
                new IFrameFetchSuccessAction({
                  iFrameData: {
                    ...this.iFrameData,
                    notifListResponse: {},
                    unreadCount: 0,
                    hasNotifResponse: true,
                    fetchNotif: false,
                    notificationError: formatApiError(error),
                  },
                })
              );
            }
          );
      }
    }
  }

  getPayloadForNotif(iframeData, FilterByDays: string = '>90') {
    let ClaimDetails = [],
      PolicyDetails = [];
    let CustomerData = {};
    const SearchKey = {
      Channel: 'webselfservice',
      SessionId: '',
      UserId: '',
    };
    let Country;

    if (iframeData && iframeData.PolicyDetails) {
      PolicyDetails = iframeData.PolicyDetails.map((el) => {
        const FormNumber = Number(el.FormNumber)
          ? Number(el.FormNumber)
          : el.FormNumber;
        const {
          AdminSystem,
          CompanyCode,
          CoverageCode,
          DueDate,
          GroupNumber,
          Policy,
          PolicyStatus,
          IsActive,
          PolicyType,
          IssueDate,
          IssueState,
          ServiceCentre,
          PaymentMethod,
          PaymentMode,
          PreferredBillingDay,
          HelplineIndicator,
          ProductDescription,
          SpanishProductDescription,
          FrenchProductDescription,
          LOB,
        } = el;

        return {
          AdminSystem,
          CompanyCode,
          CoverageCode,
          DueDate,
          FormNumber,
          GroupNumber,
          Policy,
          PolicyStatus,
          IsActive,
          PolicyType,
          IssueDate,
          IssueState,
          ServiceCentre,
          PaymentMethod,
          PaymentMode,
          PreferredBillingDay,
          HelplineIndicator,
          ProductDescription,
          SpanishProductDescription,
          FrenchProductDescription,
          LOB,
        };
      });
    }

    if (iframeData && iframeData.ClaimDetails) {
      ClaimDetails = iframeData.ClaimDetails.map((el) => {
        const ClaimNumber = Number(el.ClaimNumber)
          ? Number(el.ClaimNumber)
          : el.ClaimNumber;
        const { ClaimStatus, ClaimReportedDate, ClaimType, IsActive } = el;
        return {
          ClaimNumber,
          ClaimStatus,
          ClaimReportedDate,
          ClaimType,
          IsActive,
        };
      });
    }

    if (iframeData && iframeData.emailAdress) {
      SearchKey.UserId = iframeData.emailAdress;
    }

    if (iframeData && iframeData.uniqueId) {
      SearchKey.SessionId = iframeData.uniqueId;
    }

    const currentPage = document.location.pathname.split('/');
    const routerStr = currentPage[1];
    Country = routerStr.split('-')[1];

    // if (iframeData && iframeData.customerCountry) {
    //   Country = iframeData.customerCountry;
    // }

    if (iframeData && iframeData.CustomerDetails) {
      const CustomerIdList = iframeData?.CustomerDetails?.CustomerIdList?.map(
        (el) => el.Value
      );
      CustomerData = {
        CustomerIdList,
        Addresses: iframeData.CustomerDetails.Addresses,
        SpecialFlags: iframeData.SpecialFlags,
      };
    }

    return {
      ClaimDetails,
      SearchKey,
      PolicyDetails,
      CustomerData,
      FilterByDays,
      Country,
    };
  }

  subscribeAdb2cObj() {
    const authProvider = this._authProviderService.getAuthProvider();
    // authProvider.refreshState().subscribe((state: Adb2cState) => {
    //   this.authProviderState = cloneDeep(state);
    //   this._authProviderService.saveLoggedInSessionInfo(this.authProviderState);
    //   this.callAPIs();
    // });

    this._authProviderService.loggedInFlg$.subscribe((flg) => {
      if (flg) {
        this.authProviderState = this._authProviderService.getLoggedInSessionInfo();
        const accessToken = this._authProviderService.logginSessionData
          ?.accessToken;
        let decodedToken;
        if (accessToken !== undefined) {
          decodedToken = this._jwtHelperService.decodeToken(accessToken);
          this.sessionId = decodedToken?.nonce;
        }
        if (!this.isApisTriggered) {
          this.callAPIs();
        }
      }
    });
  }

  ngOnInit() {
    this.subscribeAdb2cObj();
  }

  openLoadingIndicator() {
    this.dialogRef = this.dialog.open(SpinningIndicatorComponent, {
      height: 'auto',
      data: {},
      disableClose: true,
    });
  }

  callAPIs() {
    this.isApisTriggered = true;

    this._service
      .getTokenAPI()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.apiToken = response.token_type + ' ' + response.access_token;
            this.sessionToken = !this.sessionToken
              ? this.authProviderState.accessToken
              : this.sessionToken;
            const tokenExpiresIn = response.expires_in;
            const showPopIn = tokenExpiresIn * 1000 - this.counter * 1000;
            setTimeout(() => {
              this.displayData = this._appContext.get('displayData');
              this._idlePopup.openPopup(this.displayData);
            }, showPopIn);

            if (this.authProviderState && this.authProviderState.isLoggedIn) {
              if (sessionStorage.getItem('callSaveRegister') === 'true') {
                this.callSaveRegisterApi();
              } else {
                this.callLoginInfoApi();
              }
            } else {
              this.iFrameData['apiKey'] = environment.webclaimURL.apiKey;
              this.iFrameData['uniqueId'] = this.sessionId;
              this.iFrameData['sessionID'] = this.sessionId;
              this.iFrameData['apiToken'] = this.apiToken;
              this.iFrameData['adToken'] = this.sessionToken;
              this._store.dispatch(
                new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
              );
              // this.dialogRef.close();
              this._loadingService.closeLoadingIndicator();
            }
          }
        },
        (error) => {
          // Do nothing
          // this.dialogRef.close();
          this._loadingService.closeLoadingIndicator();
          const formattedResponse = JSON.stringify(
            getServerError(error, environment.webclaimURL.tokenAPI)
          );
          this._router.navigate(['/knock-out'], {
            queryParams: { formattedResponse },
          });
        }
      );
  }

  callUpdateRegisterApi() {
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }
    const payload = {
      UserId: decodedToken?.email,
    };
    this._service
      .ssUpdateRegister(payload, this.apiToken, this.sessionToken)
      .pipe(timeout(120000))
      .subscribe(
        (response) => {
          // Do nothing
        },
        (error) => {
          this.showSomethingWentWrongPopup(formatApiError(error));
        }
      );
  }

  callSaveRegisterApi() {
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }

    //code to check trueGroup for Educator Market
    if (decodedToken !== undefined && decodedToken.userType !== undefined) {
      if (decodedToken.userType == 'trueGroup') {
        this.trueGroup = true;
      } else {
        this.trueGroup = false;
      }
    }
    const isReregister =
      sessionStorage.getItem('IsReregister') === 'true' ? true : false;
    const payload = {
      UserId: decodedToken?.email,
      UserRoleId: '01',
      RegisterId: sessionStorage.getItem('RegisterId'),
      IsAuthSuccess: true,
      AuthStatusJson: 'Success',
      IsReregister: isReregister,
      OldLoginId: sessionStorage.getItem('RegisteredLoginId'),
      IstrueGroupMember: this.trueGroup,
    };
    this._service
      .ssSaveRegister(payload, this.apiToken, this.sessionToken)
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (response.Code === '0000') {
            this.callLoginInfoApi();
          } else {
            this.showContactSupport();
          }
        },
        (error) => {
          // this.callLoginInfoApi();
          this.showContactSupport();
        }
      );
  }
  callSSFindPolicies() {
    let ssFindPolicy = [];
    const policiesPayload = {
      SearchKey: {
        UserId: this._authProviderService.logginSessionData.userClaims.email,
        SessionId: this.fullStoreObject['iFrameData']?.sessionId,
        Channel: environment.ssrChannel,
      },
    };
    this._service
      .ssFindPolicies(policiesPayload)
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (response !== undefined && response.Code === '0000') {
            this.iFrameData['DetailedPolicyDetails'] =
              response['PolicyDetails'];
              if (this.openedCCPANotice !== true) {
                this.iFrameData['openedCCPANotice'] = false;
                this._store.dispatch(
                  new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
                );
              }
            ssFindPolicy = [];
            if (
              response['PolicyDetails'] &&
              response['PolicyDetails'].length > 0
            ) {
              this._formValidationService.fetchPolicyDetails(
                response['PolicyDetails']
              );
              this._formValidationService.onDashboardResponse(true);
              const serviceCenter = response['PolicyDetails'][0].ServiceCentre;
              if (this._formValidationService.firstTimeApiCall) {
                if (serviceCenter === '01' || serviceCenter === '03') {
                  if (this._authProviderService.language === 'es-US') {
                    localStorage.removeItem('language');
                    this._store.dispatch(
                      new LanguageSelectionSuccessAction({
                        en: false,
                        es: true,
                        fr: false,
                      })
                    );
                    if (this.trueGroup) {
                      this._router.navigate(['es-US/memberDashboard']);
                    } else {
                      this._router.navigate(['es-US/dashboard']);
                    }
                  } else if (localStorage.getItem('language') === 'es-US') {
                    localStorage.removeItem('language');
                    this._store.dispatch(
                      new LanguageSelectionSuccessAction({
                        en: false,
                        es: true,
                        fr: false,
                      })
                    );
                    if (this.trueGroup) {
                      this._router.navigate(['es-US/memberDashboard']);
                    } else {
                      this._router.navigate(['es-US/dashboard']);
                    }
                  } else {
                    localStorage.removeItem('language');
                    if (this.trueGroup) {
                      this._router.navigate(['en-US/memberDashboard']);
                    } else {
                      this._router.navigate(['en-US/dashboard']);
                    }
                  }
                } else if (serviceCenter === '02') {
                  if (this._authProviderService.language === 'fr-CA') {
                    localStorage.removeItem('language');
                    this._store.dispatch(
                      new LanguageSelectionSuccessAction({
                        en: false,
                        es: false,
                        fr: true,
                      })
                    );
                    if (this.trueGroup) {
                      this._router.navigate(['fr-CA/memberDashboard']);
                    } else {
                      this._router.navigate(['fr-CA/dashboard']);
                    }
                  } else if (localStorage.getItem('language') === 'fr-CA') {
                    localStorage.removeItem('language');
                    this._store.dispatch(
                      new LanguageSelectionSuccessAction({
                        en: false,
                        es: false,
                        fr: true,
                      })
                    );
                    if (this.trueGroup) {
                      this._router.navigate(['fr-CA/memberDashboard']);
                    } else {
                      this._router.navigate(['fr-CA/dashboard']);
                    }
                  } else {
                    localStorage.removeItem('language');
                    if (this.trueGroup) {
                      this._router.navigate(['en-CA/memberDashboard']);
                    } else {
                      this._router.navigate(['en-CA/dashboard']);
                    }
                  }
                } else {
                  localStorage.removeItem('language');
                  if (this.trueGroup) {
                    this._router.navigate(['en-US/memberDashboard']);
                  } else {
                    this._router.navigate(['en-US/dashboard']);
                  }
                }
              }
            } else {
              this._formValidationService.onDashboardResponse(false);
              const formattedResponse =
                'PollicyDetails is empty or not present';
              this._router.navigate(['/knock-out'], {
                queryParams: { formattedResponse, dashboardKnockout: true },
              });
            }
          } else {
            this._formValidationService.onDashboardResponse(false);
            const formattedResponse =
                'Error fetching policy details';
              this._router.navigate(['/knock-out'], {
                queryParams: { formattedResponse, dashboardKnockout: true },
              });
          }
        },
        (error) => {}
      );
  }
  callDashboarInfoApi() {
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }
    const dashboardPayload = {
      UserId: decodedToken?.email,
      SessionId: this.sessionId,
    };
    if (decodedToken !== undefined && decodedToken.userType !== undefined) {
      if (decodedToken.userType == 'trueGroup') {
        this.trueGroup = true;
      } else {
        this.trueGroup = false;
      }
    }
    this._service
      .getSSDashboardInfo(dashboardPayload, this.apiToken, this.sessionToken)
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (!this._formValidationService.firstTimeApiCall) {
            this._loadingService.closeLoadingIndicator();
          }
          if (
            response?.Code === '0000' &&
            response?.PolicyDetails?.length > 0
          ) {
            response['sessionId'] = this.sessionId;
            let policyDetails = response.PolicyDetails;
            let CWBpolicyDetails = response.PolicyDetails.filter(
              (x) => x.IsCWB == true
            );
            let isCwbUser = CWBpolicyDetails.length > 0;
            response['isCwbUser'] = isCwbUser;
            if (this.systemDownData) {
              this.formatSystemDownJSON();
              response['systemDownData'] = this.systemDownData;
            }
            this._store.dispatch(
              new IFrameFetchSuccessAction({ iFrameData: response })
            );
            this.updateStore();
            setTimeout(() => {
              this.callNotifications();
            }, 6000);

            // this.dialogRef.close();
            if (!this.trueGroup) {
              this.callSSFindPolicies();
            }
            //this._router.navigate(['es-US/memberDashboard']);
          } else {
            this._formValidationService.onDashboardResponse(false);
            this._loadingService.closeLoadingIndicator();
            const formattedResponse = formatApiResponse(
              response,
              environment.ssUrl.ssDashboard
            );
            this._router.navigate(['/knock-out'], {
              queryParams: { formattedResponse, dashboardKnockout: true },
            });
            // this.showSomethingWentWrongPopup(formatApiResponse(response, environment.ssUrl.ssDashboard));
          }
        },
        (error) => {
          this._formValidationService.onDashboardResponse(false);
          // this.dialogRef.close();
          this._loadingService.closeLoadingIndicator();
          const formattedResponse = formatApiError(error);
          this._router.navigate(['/knock-out'], {
            queryParams: { formattedResponse, dashboardKnockout: true },
          });
          // const content = getApiError(error);
          // this.showSomethingWentWrongPopup(content);
        }
      );
  }

  callMemberDashboardInfoApi() {
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }
    const dashboardPayload = {
      UserId: decodedToken?.email,
      SessionId: this.sessionId,
    };
    if (decodedToken !== undefined && decodedToken.userType !== undefined) {
      if (decodedToken.userType == 'trueGroup') {
        this.trueGroup = true;
      } else {
        this.trueGroup = false;
      }
      this.iFrameData['isTrueGroupUser'] = this.trueGroup;
      this._store.dispatch(
        new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
      );
    }
    this._service
      .getSSMemberDashboardInfo(
        dashboardPayload,
        this.apiToken,
        this.sessionToken
      )
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (!this._formValidationService.firstTimeApiCall) {
            this._loadingService.closeLoadingIndicator();
          }
          if (
            response?.Code === '0000' &&
            response?.PolicyDetails?.length > 0
          ) {
            let serviceCenter = response.PolicyDetails[0].ServiceCentre;
            response['sessionId'] = this.sessionId;
            response['customerCountry'] = 'USA';
            if (this.systemDownData) {
              this.formatSystemDownJSON();
              response['systemDownData'] = this.systemDownData;
            }
            if (response['PolicyDetails']) {
              let policyWithDisabilityCoverage = response[
                'PolicyDetails'
              ].filter(
                (x) =>
                  x.ProductDescription === 'WS Group DI-ACE P&C' &&
                  x.CoverageCode === 'ACEHYDISED'
              );
              let isHavingDisabilityCoverage =
                policyWithDisabilityCoverage.length > 0 &&
                policyWithDisabilityCoverage.length !==
                  response['PolicyDetails'].length;
              let hasOnlyDICoverage =
                policyWithDisabilityCoverage.length > 0 &&
                policyWithDisabilityCoverage.length ===
                  response['PolicyDetails'].length;
              response[
                'isHavingDisabilityCoverage'
              ] = isHavingDisabilityCoverage;
              response['hasOnlyDICoverage'] = hasOnlyDICoverage;
            }
            if (response['ClaimDetails']) {
              let claimNumbers = [];
              claimNumbers = Object.entries(response['ClaimDetails']).map(
                ([k, v]) => '' + v['ClaimNumber'] + ''
              );
              if (claimNumbers !== undefined && claimNumbers.length > 0) {
                this._formService
                  .getClaimsStatusOT(claimNumbers, this.iFrameData['uniqueId'])
                  .subscribe(
                    (response2: any) => {
                      if (
                        response2 &&
                        response2.ClaimActivities &&
                        response2.ClaimActivities.length > 0
                      ) {
                        this.expandData = response2.ClaimActivities;
                        this.updateClaimStatusesForAllClaims(
                          response['ClaimDetails'],
                          this.expandData
                        );
                      } else {
                        // this._store.dispatch(
                        //   new LoadingIndicatorHideAction('document-header')
                        // );
                      }
                    },
                    (error) => {
                      // this._store.dispatch(
                      //   new LoadingIndicatorHideAction('document-header')
                      // );
                    }
                  );
              }
            }
            this._store.dispatch(
              new IFrameFetchSuccessAction({ iFrameData: response })
            );
            this.updateStore();
            this._formValidationService.onDashboardResponse(true);
            this._loadingService.closeLoadingIndicator();
            if (this._formValidationService.firstTimeApiCall) {
              if (serviceCenter === '01' || serviceCenter === '03') {
                if (this._authProviderService.language === 'es-US') {
                  localStorage.removeItem('language');
                  this._store.dispatch(
                    new LanguageSelectionSuccessAction({
                      en: false,
                      es: true,
                      fr: false,
                    })
                  );
                  if (this.trueGroup) {
                    this._router.navigate(['es-US/memberDashboard']);
                  } else {
                    this._router.navigate(['es-US/dashboard']);
                  }
                } else if (localStorage.getItem('language') === 'es-US') {
                  localStorage.removeItem('language');
                  this._store.dispatch(
                    new LanguageSelectionSuccessAction({
                      en: false,
                      es: true,
                      fr: false,
                    })
                  );
                  if (this.trueGroup) {
                    this._router.navigate(['es-US/memberDashboard']);
                  } else {
                    this._router.navigate(['es-US/dashboard']);
                  }
                } else {
                  localStorage.removeItem('language');
                  if (this.trueGroup) {
                    this._router.navigate(['en-US/memberDashboard']);
                  } else {
                    this._router.navigate(['en-US/dashboard']);
                  }
                }
              } else if (serviceCenter === '02') {
                if (this._authProviderService.language === 'fr-CA') {
                  localStorage.removeItem('language');
                  this._store.dispatch(
                    new LanguageSelectionSuccessAction({
                      en: false,
                      es: false,
                      fr: true,
                    })
                  );
                  if (this.trueGroup) {
                    this._router.navigate(['fr-CA/memberDashboard']);
                  } else {
                    this._router.navigate(['fr-CA/dashboard']);
                  }
                } else if (localStorage.getItem('language') === 'fr-CA') {
                  localStorage.removeItem('language');
                  this._store.dispatch(
                    new LanguageSelectionSuccessAction({
                      en: false,
                      es: false,
                      fr: true,
                    })
                  );
                  if (this.trueGroup) {
                    this._router.navigate(['fr-CA/memberDashboard']);
                  } else {
                    this._router.navigate(['fr-CA/dashboard']);
                  }
                } else {
                  localStorage.removeItem('language');
                  if (this.trueGroup) {
                    this._router.navigate(['en-CA/memberDashboard']);
                  } else {
                    this._router.navigate(['en-CA/dashboard']);
                  }
                }
              } else {
                localStorage.removeItem('language');
                if (this.trueGroup) {
                  this._router.navigate(['en-US/memberDashboard']);
                } else {
                  this._router.navigate(['en-US/dashboard']);
                }
              }
            }
            // this._router.navigate(['en-US/memberDashboard']);
          } else {
            this._formValidationService.onDashboardResponse(false);
            this._loadingService.closeLoadingIndicator();
            const formattedResponse = formatApiResponse(
              response,
              environment.ssUrl.ssDashboard
            );
            this._router.navigate(['/knock-out'], {
              queryParams: { formattedResponse, dashboardKnockout: true },
            });
          }
        },
        (error) => {
          this._formValidationService.onDashboardResponse(false);
          // this.dialogRef.close();
          this._loadingService.closeLoadingIndicator();
          const formattedResponse = formatApiError(error);
          this._router.navigate(['/knock-out'], {
            queryParams: { formattedResponse, dashboardKnockout: true },
          });
          // const content = getApiError(error);
          // this.showSomethingWentWrongPopup(content);
        }
      );
  }

  updateClaimStatusesForAllClaims(claimsInfo, activityDetails) {
    activityDetails.forEach((claim) => {
      // if (claim.ClaimStatus !== null) {
      claimsInfo.forEach((x) => {
        if (x.ClaimNumber === claim.ClaimNumber) {
          if (
            claim.ClaimStatus !== null &&
            claim.ClaimStatus === 'Full Review' &&
            x.ClaimStatus === 'Under Review'
          ) {
            x.ClaimStatus = 'Under Review';
          } else if (
            claim.ClaimStatus !== null &&
            claim.ClaimStatus === 'Processed'
          ) {
            if (
              x.DispositionCode.startsWith('P') ||
              x.DispositionCode.startsWith('0') ||
              x.ClaimStatus === 'Paid'
            ) {
              x.ClaimStatus = 'Paid';
            } else if (
              x.DispositionCode.startsWith('2') ||
              x.ClaimStatus === 'Denied'
            ) {
              x.ClaimStatus = 'Denied';
            } else if (
              x.DispositionCode.startsWith('4') ||
              x.ClaimStatus === 'Closed'
            ) {
              x.ClaimStatus = 'Closed';
            }
          } else if (claim.ClaimStatus !== null) {
            if (x.ClaimStatus === 'Paid') {
              x.ClaimStatus = 'Paid';
            } else if (x.ClaimStatus === 'Denied') {
              x.ClaimStatus = 'Denied';
            } else if (x.ClaimStatus === 'Closed') {
              x.ClaimStatus = 'Closed';
            } else {
              x.ClaimStatus = claim.ClaimStatus;
            }
          } else {
            if (x.DispositionCode === '101') {
              x.ClaimStatus = 'New Claim Received';
            } else if (
              x.DispositionCode.startsWith('P') ||
              x.DispositionCode.startsWith('0') ||
              x.ClaimStatus === 'Paid'
            ) {
              x.ClaimStatus = 'Paid';
            } else if (
              x.DispositionCode.startsWith('2') ||
              x.ClaimStatus === 'Denied'
            ) {
              x.ClaimStatus = 'Denied';
            } else if (
              x.DispositionCode.startsWith('4') ||
              x.ClaimStatus === 'Closed'
            ) {
              x.ClaimStatus = 'Closed';
            }
          }
          return x;
        }
      });
      this.iFrameData['ClaimDetailsFinal'] = claimsInfo;
      this.iFrameData['activityDetails'] = activityDetails;
      this._store.dispatch(
        new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
      );
    });
  }

  updateStore() {
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }
    this.iFrameData['apiKey'] = environment.webclaimURL.apiKey;
    this.iFrameData['uniqueId'] = this.sessionId;
    this.iFrameData['sessionID'] = decodedToken?.nonce;
    this.iFrameData['emailAdress'] =
      decodedToken.email ||
      this.authProviderState.userClaims.email ||
      this._authProviderService.logginSessionData.userClaims.email;
    this.iFrameData['apiToken'] = this.apiToken;
    this.iFrameData['adToken'] = this.sessionToken;
    this._store.dispatch(
      new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
    );
  }

  callLoginInfoApi() {
    if (this.authProviderState && this.authProviderState.userClaims) {
      const accessToken = this._authProviderService.logginSessionData
        ?.accessToken;
      let decodedToken;
      if (accessToken !== undefined) {
        decodedToken = this._jwtHelperService.decodeToken(accessToken);
      }
      const expiryDate = new Date(this.authProviderState.userClaims.exp * 1000);
      const requestBody = {
        UserId: decodedToken?.email,
        UserAction: 'Login',
        SessionId: decodedToken?.nonce,
        Token: this.sessionToken,
        TokenValidEndDttm: moment(expiryDate).format('DD/MM/YYYY HH:mm:ss'),
      };

      //code to check trueGroup for Educator Market
      if (decodedToken !== undefined && decodedToken.userType !== undefined) {
        if (decodedToken.userType == 'trueGroup') {
          this.trueGroup = true;
        } else {
          this.trueGroup = false;
        }
      }

      this._service
        .ssLoginInfoAPI(requestBody, this.apiToken)
        .pipe(timeout(120000))
        .subscribe(
          (response) => {
            if (response['Code'] === '0000') {
              if (response['IsActive'] === true) {
                if (!this.systemDownData) {
                  this._service.getSystemDownTimeJson().subscribe(
                    (resp) => {
                      this.systemDownData = resp;
                      if (
                        this.systemDownData &&
                        this.systemDownData.module &&
                        this.systemDownData.module.main &&
                        this.systemDownData.module.main.msg
                      ) {
                        this.formatSystemDownJSON();
                        this._loadingService.closeLoadingIndicator();
                        this._router.navigate(['/knock-out'], {
                          queryParams: {
                            formattedResponse: '',
                            systemDownData: JSON.stringify(this.systemDownData),
                            dashboardKnockout: true,
                          },
                        });
                      } else {
                        this.updateStore();
                        if (this.trueGroup === true) {
                          this.callMemberDashboardInfoApi();
                        } else {
                          this.callDashboarInfoApi();
                        }

                        if (
                          !(
                            sessionStorage.getItem('callSaveRegister') ===
                            'true'
                          )
                        ) {
                          this.callUpdateRegisterApi();
                        }
                        sessionStorage.clear();
                      }
                    },
                    (err) => {
                      this.updateStore();
                      if (this.trueGroup === true) {
                        this.callMemberDashboardInfoApi();
                      } else {
                        this.callDashboarInfoApi();
                      }
                      if (
                        !(sessionStorage.getItem('callSaveRegister') === 'true')
                      ) {
                        this.callUpdateRegisterApi();
                      }
                      sessionStorage.clear();
                    }
                  );
                } else {
                  if (
                    this.systemDownData &&
                    this.systemDownData.module &&
                    this.systemDownData.module.main &&
                    this.systemDownData.module.main.msg
                  ) {
                    this.formatSystemDownJSON();
                    this._loadingService.closeLoadingIndicator();
                    this._router.navigate(['/knock-out'], {
                      queryParams: {
                        formattedResponse: '',
                        systemDownData: JSON.stringify(this.systemDownData),
                        dashboardKnockout: true,
                      },
                    });
                  } else {
                    this.updateStore();
                    if (this.trueGroup === true) {
                      this.callMemberDashboardInfoApi();
                    } else {
                      this.callDashboarInfoApi();
                    }
                    if (
                      !(sessionStorage.getItem('callSaveRegister') === 'true')
                    ) {
                      this.callUpdateRegisterApi();
                    }
                    sessionStorage.clear();
                  }
                }
              } else {
                //show popup
                // const content = {
                //   subHeader: this.inactiveMessage,
                // };
                // this.showSomethingWentWrongPopup(content);
                this.showContactSupport();
              }
            } else {
              // const content = {
              //   subHeader:
              //     'Code: ' + response['Code'] + ', ' + response['Message'],
              // };
              // this.showSomethingWentWrongPopup(content);
              this.showContactSupport();
            }
          },
          (error) => {
            this.showSomethingWentWrongPopup(formatApiError(error));
          }
        );
    }
  }

  ngAfterViewInit() {}

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    // if (event instanceof NavigationStart) {
    //   this.dialogRef.close();
    // }
    // if (event instanceof NavigationEnd) {
    //   this.dialogRef.close();
    // }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    // if (event instanceof NavigationCancel) {
    //   this.dialogRef.close();
    // }
    // if (event instanceof NavigationError) {
    //   this.dialogRef.close();
    // }
  }

  showContactSupport() {
    this._loadingService.closeLoadingIndicator();
    const popUpData = cloneDeep(this.contactSupportPopUp);
    const popUp = this.dialog.open(GeneralizedPopUpComponent, popUpData);
    popUp.afterClosed().subscribe((event) => {
      sessionStorage.clear();
      let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
      const path = localStorage.getItem('language');
      if (
        path &&
        path !== '' &&
        (path === 'en-US' ||
          path === 'es-US' ||
          path === 'en-CA' ||
          path === 'fr-CA')
      ) {
        logoutUrl = logoutUrl + path + '/login';
      } else {
        logoutUrl = logoutUrl + 'en-US/login';
      }
      localStorage.removeItem('loggedIn');
      const authProvider = this._authProviderService.getAuthProvider();
      authProvider.logout(
        environment.msalConfig.logoutConfig.logoutRedirectUrl
      );
    });
  }

  showSomethingWentWrongPopup(errorResponse) {
    this._loadingService.closeLoadingIndicator();
    const popUpData = cloneDeep(this.serverError);
    popUpData['data']['errorResponse'] = errorResponse;
    const popUp = this.dialog.open(GeneralizedPopUpComponent, popUpData);
    popUp.afterClosed().subscribe((event) => {
      sessionStorage.clear();
      let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
      const path = localStorage.getItem('language');
      if (
        path &&
        path !== '' &&
        (path === 'en-US' ||
          path === 'es-US' ||
          path === 'en-CA' ||
          path === 'fr-CA')
      ) {
        logoutUrl = logoutUrl + path + '/login';
      } else {
        logoutUrl = logoutUrl + 'en-US/login';
      }
      localStorage.removeItem('loggedIn');
      const authProvider = this._authProviderService.getAuthProvider();
      authProvider.logout(logoutUrl);
    });
  }

  formatSystemDownJSON() {
    if (this.systemDownData) {
      for (let item in this.systemDownData.module) {
        if (this.systemDownData.module[item].msg) {
          this.systemDownData.module[item].msg = this.systemDownData.module[
            item
          ].msg.replace(/\s*/g, '');
          this.systemDownData.module[item].msg = this.systemDownData.module[
            item
          ].msg.toLowerCase();
        }
        if (this.systemDownData.module[item].downtime) {
          this.systemDownData.module[
            item
          ].downtime = this.systemDownData.module[item].downtime.trim();
        }
      }
    }
  }
}
